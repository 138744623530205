<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Please sign in with your credentials</small>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="myemail@example.com"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
              v-model="formData.email"
              required
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              type="password"
              placeholder="Password"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
              v-model="formData.password"
              required
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <!-- <v-checkbox
              v-model="checkbox"
              color="#5e72e4"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0">Remember me</span>
              </template>
            </v-checkbox> -->

            <v-btn v-on:click="submit"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#ef4036"
              type="submit"
              >Sign In</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <!-- <a
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>Forgot password?</small></a
        >
        <a
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>Create new account</small></a
        > -->
      </div>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: "login",
  data: function () {
    return {
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        login: `${this.$siteConfig.resellerApiBasePath}/reseller/login`,
      },
      checkbox: false,
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      event.preventDefault();
      console.log('CLICKED!!!');
      let body = {email: this.formData.email, password: this.formData.password};
      console.log(body);
      if (body.email && body.password) {
        fetch(`${this.$data.host}${this.$data.paths.login}`, {
          method: 'POST',
          mode: 'cors', // no-cors, *cors, same-origin
          // credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body) // body data type must match "Content-Type" header
        })
        .then(response => {
          console.log(response);
          if (response.ok) {
            console.log('OK');
            return response.json()
          } else {
            console.log('NOT OK');
            const contentType = response.headers.get('content-type');
            console.log(contentType);
            if (!contentType || !contentType.includes('application/json')) {
              return response.text();
            } else {
              return response.json();
            }
          }
        })
        .then(json => {
          console.log('DATA:', json);
          if (typeof json === 'string') { throw new Error(json.substring(0,100)); }

          if (json && json.token) {
            localStorage.setItem('jwt', json.token);
            localStorage.setItem('rebilly_user_id', json.rebilly_user_id);
            localStorage.setItem('user', JSON.stringify({login: json.login, email: body.email, rebilly_user_id: json.rebilly_user_id}));
            this.$router.push(this.$route.query.redirect || '/')
          } else if (json && json.error) {
            alert(json.error);
          } else {
            throw new Error(`Unknown API response: ${JSON.stringify(json)}`);
          }
        })
        .catch((e) => {
          alert(`Server error: ${e.message}`); // retry only here
          console.log('Fetch error', e);
        });
      }
      //this.$v.$touch();
    },
  }
};
</script>
