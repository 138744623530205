<template>
  <v-row class="justify-center d-flex mt-n16">
    <v-col cols="12">
      <div class="d-flex justify-center flex-column flex-md-row">
        <v-card class="card-shadow mb-6">
          <div class="card-header-padding text-center card-border-bottom">
            <h4
              class="text-h4 font-weight-600 text-uppercase py-4 text-primary"
            >
              Bravo Pack
            </h4>
          </div>
          <v-card-text class="card-padding text-center card-border-bottom">
            <div class="display-h-2 font-weight-600 text-body">$49</div>
            <span class="font-size-root text-muted"> per application</span>

            <v-list class="py-6 mx-auto px-15" color="transparent">
              <v-list-item
                v-for="item in features"
                :key="item.title"
                class="px-0 py-0"
              >
                <v-list-item-avatar
                  :size="24"
                  color="#fff"
                  class="my-0 me-2 bg-gradient-primary"
                >
                  <v-icon size="9" class="text-white">{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title
                    v-text="item.title"
                    class="text-body-1 ls-0 text-muted"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ls-0"
              color="#5e72e4"
              >Start Free Trial</v-btn
            >
          </v-card-text>
          <div class="card-header-padding text-center">
            <a
              href="javascript:;"
              class="text-decoration-none text-body-2 ls-0 font-size-root text-muted no-default-hover"
              target="_blank"
              >Request a demo</a
            >
          </div>
        </v-card>

        <v-card class="card-shadow my-6 mt-md-0 bg-gradient-success scale-1-1">
          <div class="card-header-padding text-center card-border-bottom">
            <h4 class="text-h4 font-weight-600 text-uppercase py-4 text-white">
              Alpha Pack
            </h4>
          </div>
          <v-card-text class="card-padding text-center card-border-bottom">
            <div class="display-h-2 font-weight-600 text-white">$199</div>
            <span class="font-size-root text-white"> per application</span>

            <v-list class="py-6 px-15 mx-auto" color="transparent">
              <v-list-item
                v-for="item in features"
                :key="item.title"
                class="px-0 py-0"
              >
                <v-list-item-avatar :size="24" color="#fff" class="my-0 me-2">
                  <v-icon size="9" class="text-muted">{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title
                    v-text="item.title"
                    class="text-body-1 ls-0 text-white"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm ls-0"
              color="#fff"
              >Start Free Trial</v-btn
            >
          </v-card-text>
          <div class="card-header-padding text-center">
            <a
              href="javascript:;"
              class="text-decoration-none text-body-2 ls-0 font-size-root text-white no-default-hover"
              target="_blank"
              >Contact Sales</a
            >
          </div>
        </v-card>
      </div>
    </v-col>

    <v-col cols="12" sm="10">
      <div class="d-flex justify-center align-center px-3 mt-12">
        <v-avatar color="#fff" size="48" class="me-1">
          <v-icon class="text-primary" size="20">ni-building</v-icon>
        </v-avatar>
        <v-col md="6">
          <p class="text-white font-weight-thin font-size-root mb-0">
            <strong>The Arctic Ocean</strong> freezes every winter and much of
            the sea-ice then thaws every summer, and that process will continue
            whatever.
          </p>
        </v-col>
      </div>
    </v-col>

    <v-col cols="12" sm="8">
      <v-simple-table light class="bg-default table table-border-dark mt-10">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-light font-weight-bold">Features</th>
              <th class="text-left text-white font-weight-bold">Bravo Pack</th>
              <th class="text-left text-white font-weight-bold">Alpha Pack</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.name">
              <td class="text-white text-caption ls-0">{{ item.title }}</td>
              <td v-html="item.bravo"></td>
              <td v-html="item.alpha"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "pricing",
  data() {
    return {
      features: [
        {
          icon: "fas fa-terminal",
          title: "Complete documentation",
        },
        {
          icon: "fas fa-pen-fancy",
          title: "Working materials in Sketch",
        },
        {
          icon: "fas fa-hdd",
          title: "2GB cloud storage",
        },
      ],
      desserts: [
        {
          title: "IMAP/POP Support",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: "Email Forwarding",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: "Active Sync",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: "Multiple domain hosting",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha:
            '<span class="text-sm text-light">Limited to 1 domain only</span>',
        },
        {
          title: "Additional storage upgrade",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<i class="fas fa-check text-success">',
        },
        {
          title: "30MB Attachment Limit",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<span class="text-white">-</span>',
        },
        {
          title: "Password protected / Expiry links",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<span class="text-white">-</span>',
        },
        {
          title: "Unlimited Custom Apps",
          bravo: '<i class="fas fa-check text-success"></i>',
          alpha: '<span class="text-white">-</span>',
        },
      ],
    };
  },
};
</script>
