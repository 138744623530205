<template>
  <v-card class="card-shadow mb-6">
    <v-img height="258" src="@/assets/img/img-1-1000x600.jpg"></v-img>
    <v-avatar class="avatar-absolute" size="140">
      <v-img src="@/assets/img/team-4.jpg"></v-img>
    </v-avatar>
    <div class="card-header-padding text-center">
      <div class="d-flex justify-space-between">
        <v-btn
          small
          elevation="0"
          :ripple="false"
          height="28"
          class="font-weight-600 text-capitalize btn-ls btn-info rounded-sm px-2 py-1"
          color="#11cdef"
          >Connect</v-btn
        >

        <v-btn
          small
          elevation="0"
          :ripple="false"
          height="28"
          class="font-weight-600 text-capitalize btn-ls btn-default rounded-sm px-2 py-1"
          color="#172b4d"
          >Message</v-btn
        >
      </div>
    </div>

    <v-card-text class="card-padding pt-0">
      <v-list class="py-4">
        <v-list-item class="px-0 py-4">
          <v-row class="d-flex justify-center">
            <v-col class="py-0" cols="auto" v-for="item in 3" :key="item">
              <v-list-item-content class="text-center">
                <span
                  class="text-h3 font-weight-bold text-body mb-1"
                  v-if="item === 1"
                  >22</span
                >
                <span
                  class="text-h3 font-weight-bold text-body mb-1"
                  v-else-if="item === 2"
                  >10</span
                >
                <span class="text-h3 font-weight-bold text-body mb-1" v-else
                  >89</span
                >
                <v-list-item-title
                  v-if="item === 1"
                  class="description text-body-2 ls-0 mb-2"
                  >Friends</v-list-item-title
                >
                <v-list-item-title
                  v-else-if="item === 2"
                  class="description text-body-2 ls-0 mb-2"
                  >Photos</v-list-item-title
                >
                <v-list-item-title
                  v-else
                  class="description text-body-2 ls-0 mb-2"
                  >Comments</v-list-item-title
                >
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>

      <div class="text-center">
        <h3 class="text-typo font-weight-600 text-h3 mb-2">
          Jessica Jones<span class="font-weight-light">, 27</span>
        </h3>
        <div class="text-h5 text-typo font-weight-thin">Bucharest, Romania</div>
        <div class="text-h5 text-typo font-weight-600 mt-5">
          Solution Manager - Creative Tim Officer
        </div>
        <div class="text-body font-size-root mt-3">
          University of Computer Science
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-profile",
};
</script>
