<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Please sign in with credentials</small>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Login"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-hat-3</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Email"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-4"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Password"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <div class="text-muted font-italic mb-4 text-left">
              <small
                >password strength:
                <span class="text-success font-weight-600">strong</span></small
              >
            </div>

            <v-checkbox
              v-model="checkbox"
              color="#5e72e4"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0"
                  >I agree with the
                  <a
                    href="javascript:;"
                    class="text-primary text-decoration-none"
                    >Privacy Policy</a
                  ></span
                >
              </template>
            </v-checkbox>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              >Create Account</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "register",
  data() {
    return {
      checkbox: false,
    };
  },
};
</script>
