<template>
  <div>
    <v-img
      src="@/assets/img/hero.svg"
      height="500"
      position="center top"
    >
      <div class="d-flex align-center h-100">
        <div class="mask bg-gradient-default opacity-9"></div>
        <v-container fluid class="z-index-1 px-6">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <h1 class="display-h-2 text-white font-weight-600 mb-2">
                Edit proxy user profile
              </h1>
              <p class="text-white mt-0 mb-12 font-weight-thin">
                Edit an existing proxy user profile or completely remove it from the system. If you reduce the available amount of traffic for the user - it will be returned to the reseller account.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-img>

<!-- ------------------------------------------------------------------------- -->
<!-- LOADING DIALOG -->

    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      persistent
      width="150"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="py-5">
          Loading...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- ------------------------------------------------------------------------- -->
<!-- MESSAGE DIALOG -->

    <v-dialog
      v-model="dialogMessage"
      width="300"
    >
      <v-card>
        <v-card-text class="py-5">
          {{ messageText }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="#adb5bd" block @click="dialogMessage = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!-- ------------------------------------------------------------------------- -->

    <v-row class="px-6 mt-n16">
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="card-shadow mb-6 bg-gradient-info">
              <v-card-text class="card-stats-padding">
                <v-row no-gutters>
                  <v-col>
                    <div
                      class="font-weight-600 text-white text-uppercase text-h5"
                    >
                      Total bandwidth available
                    </div>
                    <p class="font-weight-600 text-h2 text-white mb-0">
                      {{ reseller.balanceString }}
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <v-avatar color="#fff">
                      <v-icon size="20" class="text-default">
                        ni-active-40
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>

                <p
                  class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
                >
                  <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                  <span class="text-white me-2">increased</span> since last month
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="card-shadow bg-gradient-danger mb-6">
              <v-card-text class="card-stats-padding">
                <v-row no-gutters>
                  <v-col>
                    <div
                      class="font-weight-600 text-white text-uppercase text-h5"
                    >
                      Total proxy users
                    </div>
                    <p class="font-weight-600 text-h2 text-white mb-0">
                      {{ reseller.usersTotal }}
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <v-avatar color="#fff">
                      <v-icon size="20" class="text-default">
                        ni-spaceship
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>

                <p
                  class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
                >
                  <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                  <span class="text-white me-2">increased</span> since last month
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <v-row>
              <v-col>
                <p class="font-weight-600 text-h3 text-typo mb-0">
                  Select Proxy User
                </p>
              </v-col>

              <v-col class="text-right">

              </v-col>
            </v-row>
          </div>

          <v-card-text class="card-padding">
            <div class="ps-lg-5">
              <v-row>
                <v-col cols="12" lg="12">
                  <v-tooltip color="#312529" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        item-text="login"
                        item-value="id"
                        @keydown.enter.prevent="enterClicked"
                        v-model="selectedUser"
                        v-on:change="changeUser"
                        :items="reseller.usersList"
                        class="input-style font-size-input text-light-input placeholder-light select-style"
                        outlined
                        single-line
                      ></v-autocomplete>
                    </template>
                    <span>Input part of a login and press Enter to find matches</span>
                  </v-tooltip>

                  <!-- <v-select
                    item-text="login"
                    item-value="id"
                    v-model="selectedUser"
                    v-on:change="changeUser"
                    :items="reseller.usersList"
                    class="input-style font-size-input text-light-input placeholder-light select-style"
                    outlined
                    single-line
                  >
                  </v-select> -->
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <v-row>
              <v-col>
                <p class="font-weight-600 text-h3 text-typo mb-0">
                  Edit Profile
                </p>
              </v-col>

              <v-col class="text-right">

              </v-col>
            </v-row>
          </div>
          <v-card-text class="card-padding">
            <v-form>
              <div class="ps-lg-5">
                <v-row>
                  <v-col>
                    <card-pricing :selectedUser="selectedUser"></card-pricing>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >New Password</label
                    >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showPassword"
                    v-model="user.password"
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <v-icon @click="showHidePass" size=".875rem" color="#adb5bd">fas fa-eye</v-icon>
                    </template>
                  </v-text-field>

                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Confirm password</label
                    >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showPassword"
                    v-model="user.password2"
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <v-icon @click="showHidePass" size=".875rem" color="#adb5bd">fas fa-eye</v-icon>
                    </template>
                  </v-text-field>
                  </v-col>
                </v-row>

              </div>
            </v-form>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Proxy access configuration
            </h6>

            <div class="ps-lg-5">
              <v-row>

                <v-col cols="2">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Add or remove traffic</label
                  >

                    <v-switch
                      :ripple="false"
                      class="d-inline-flex mt-0 pt-0 switch me-2"
                      v-model="user.add"
                      hide-details
                      color="#292B2D"
                      inset
                    ></v-switch>

                </v-col>


                <v-col cols="10">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >{{ user.add ? "ADD" : "REMOVE" }} [GB]</label
                  >

                  <v-text-field
                    v-model="user.balanceGb" :min="0" :max="reseller.limit"
                    type="number"
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    placeholder="1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="pb-0">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Change Bandwidth</label
                  >
                    <v-slider

                      v-model="user.balanceGb" :min="0" :max="reseller.limit"
                      color="bg-primary"
                      track-color="#e9ecef"
                      class="progress-shadow mb-2"
                      thumb-label
                      hint="Im a hint"
                    ></v-slider>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Suspend/Activate account</label
                  >

                    <v-switch
                      :ripple="false"
                      class="d-inline-flex mt-0 pt-0 switch me-2"
                      v-model="user.active"
                      hide-details
                      color="#292B2D"
                      inset
                    ></v-switch>

                </v-col>
                <v-col class="text-right">

                  <v-btn
                    @click="clearTraffic"
                    elevation="0"
                    small
                    :ripple="false"
                    :disabled="sDisabled || !this.$data.user.id"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                    color="#ef4036"
                    >Remove all bandwidth</v-btn
                  >


                </v-col>
              </v-row>

            </div>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Description
            </h6>

            <div class="ps-lg-5">
              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >About the user</label
                  >
                  <v-textarea
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light"
                    dense
                    flat
                    filled
                    solo
                    height="106"
                    name="input-7-4"
                    v-model="user.description"
                    placeholder="Amazon scraping, ~300k requests/day"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>

            <div class="border-bottom my-5"></div>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  :disabled="sDisabled || !this.$data.user.id"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="bg-default"
                  >Submit</v-btn
                >

                <v-btn
                  @click="cancel"
                  elevation="0"
                  :ripple="false"
                  :disabled="sDisabled || !this.$data.user.id"
                  height="43"
                  class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm me-2"
                  color="transparent"
                  >Cancel</v-btn
                >
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>
<script>
import CardProfile from "../Components/Cards/CardProfile.vue";
import CardPricing from "../Components/Cards/CardPricing.vue";
import ProgressTrack from "../Dashboard/Widgets/ProgressTrack.vue";

export default {
  components: { CardProfile, CardPricing, ProgressTrack },
  name: "profile",
  data() {
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        resellerInfo: `${this.$siteConfig.resellerApiBasePath}/reseller/info`,
        users: `${this.$siteConfig.resellerApiBasePath}/reseller/users`,
        usersCount: `${this.$siteConfig.resellerApiBasePath}/reseller/users-count`,
      },
      dialogLoading: false,
      dialogMessage: false,
      messageText: 'Everything is OK :)',
      sdisabled: false,
      showPassword: 'password',
      reseller: {
        balance: 1,
        balanceString: '0 KB',
        limit: 0,
        usersTotal: 0,
        usersList: [/*{login: "login", id: "id"}, {login: "login2", id: "id2"}*/]
      },
      selectedUser: '',
      user: {
        id: '',
        ips: ['-'],
        login: '',
        email: '',
        active: false,
        created: '',
        balance: 0,
        balanceGb: 0,
        balanceString: '',
        description: '',
        password: '',
        password2: '',
        add: true,
      },
    }
  },
  methods: {
    getResellerMainInfo: function() {
        // this.$data.dialogLoading = true;

        fetch(`${this.$data.host}${this.$data.paths.usersCount}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.reseller.balance = json.data.trafficBalanceKb;
                this.$data.reseller.limit = Math.floor(json.data.trafficBalanceKb / 1024 / 1024);
                this.$data.reseller.balanceString = json.data.trafficBalanceString;
                this.$data.reseller.usersTotal = json.data.users.all;
                // this.$data.reseller.usersList = json.data.users.active.concat(json.data.proxyUsers.suspended);
                // this.$data.dialogLoading = false;
              } catch(e) {
                // this.$data.dialogLoading = false;
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              // this.$data.dialogLoading = false;
              console.log(json.error);
              // this.$data.dialogMessage = true;
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            this.$data.dialogLoading = false;
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },



    getResellerInfo: function(search) {
      if (!search) {
        this.$data.messageText = 'Search query is required!';
        this.$data.dialogMessage = true;
        return;
      }
      console.log('USER:');
      console.log(this.$route.query.user);
        this.$data.dialogLoading = true;

        fetch(`${this.$data.host}${this.$data.paths.resellerInfo}?search=${search}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.reseller.balance = json.data.trafficBalanceKb;
                this.$data.reseller.limit = Math.floor(json.data.trafficBalanceKb / 1024 / 1024);
                this.$data.reseller.balanceString = json.data.trafficBalanceString;
                this.$data.reseller.usersTotal = json.data.proxyUsers.all.length;
                this.$data.reseller.usersList = json.data.proxyUsers.active.concat(json.data.proxyUsers.suspended);
                this.$data.dialogLoading = false;
              } catch(e) {
                this.$data.dialogLoading = false;
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              this.$data.dialogLoading = false;
              this.$data.messageText = json.error;
              this.$data.dialogMessage = true;
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            this.$data.dialogLoading = false;
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },

    getProxyUserInfo: function() {
      console.log('USER:');
      console.log(this.$route.query.user);
        const proxyUserId = this.$route.query.user;
        if (!proxyUserId) {
          return;
        }
        const path = `${this.$data.paths.users}/${proxyUserId}/info`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                let buf = {username: json.data.login, id: json.data.id};
                this.$data.reseller.usersList.push(buf);
                this.$data.selectedUser = buf.id;
                this.$data.user.id = json.data.id;
                this.$data.user.login = json.data.login;
                this.$data.user.email = json.data.email;
                this.$data.user.active = json.data.proxyUserStatus === 'ACTIVE' ? true : false;
                this.$data.user.created = json.data.created;
                this.$data.user.balance = json.data.trafficBalanceKb;
                this.$data.user.balanceString = json.data.trafficBalanceString;
                this.$data.user.description = json.data.description;
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }

            } else if (json && json.error) {
              this.$data.messageText = json.error;
              this.$data.dialogMessage = true;
            } else {
              throw new Error('Authentication error');
            }
          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },

    getIPs: function() {
      console.log(this.$route.query.user);
        const proxyUserId = this.$route.query.user;
        if (!proxyUserId) {
          return;
        }
        const path = `${this.$data.paths.users}/${proxyUserId}/client-ips`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.user.ips = json.data;
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }

            } else if (json && json.error) {
              // this.$data.messageText = json.error;
              // this.$data.dialogMessage = true;
              console.log('Can not fetch client IPs list', json.error);
            } else {
              // throw new Error('Authentication error');
              console.log('Can not fetch client IPs list');
            }
          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
        });
    },

    submit: function() {
      console.log('CLICKED!!!');
      this.$data.sDisabled = true;

      let editedBody = {
        description: this.$data.user.description,
        balance: parseFloat(this.$data.user.balance + this.$data.user.balanceGb * 1024 * 1024),
        status : this.$data.user.active ? "ACTIVE" : "SUSPENDED"
      }

      if (!this.$data.user.add) {
        editedBody.balance = parseFloat(this.$data.user.balance - this.$data.user.balanceGb * 1024 * 1024);
      }
      if (editedBody.balance < 0) {
        this.$data.messageText = 'User balance is too low';
        this.$data.dialogMessage = true;
        this.$data.sDisabled = false;
        return;
      }

      if (this.$data.user.password || this.$data.user.password2) {
        if (this.$data.user.password != this.$data.user.password2) {
          this.$data.messageText = 'Passwords must match!';
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
          return;
        } else {
          if (!/^[a-zA-Z0-9_-]*$/.test(this.$data.user.password)) {
            this.$data.messageText = 'Password cannot contain special characters or whitespaces. Please use a password that does not contain special characters.';
            this.$data.dialogMessage = true;
            this.$data.sDisabled = false;
            return;
          }
          editedBody.password = this.$data.user.password;
        }
      }
      console.log('PAYLOAD:', editedBody);

      if (!this.$data.user.id) {
        this.$data.messageText = 'No user selected';
        this.$data.dialogMessage = true;
        this.$data.sDisabled = false;
        return;
      }
      fetch(`${this.$data.host}${this.$data.paths.users}/${this.$data.user.id}/edit`, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.$data.token}`
        },
        body: JSON.stringify(editedBody) // body data type must match "Content-Type" header
      })
      .then(response => {
        if (response.ok) {
          console.log('OK');
          return response.json()
        } else {
          console.log('NOT OK');
          console.log(response);
          return response.json();
        }
      })
      .then(json => {
        console.log('DATA:', json);
        if (json && json.status === 'SUCCESS') {
          this.$data.messageText = 'Successfully updated!';
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
          this.$router.push(this.$route.query.redirect || '/')
        } else if (json && json.error) {
          this.$data.messageText = json.error;
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
        } else {
          this.$data.messageText = 'Authentication error';
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
        }
      })
      .catch((e) => {
        // alert('Server error');
        console.log('Fetch error', e);
        this.$data.sDisabled = false;
      });
    },
    cancel: function() {
      this.$router.push('/dashboard');
    },
    clearTraffic: function() {
      console.log('CLICKED!!!');
      this.$data.sDisabled = true;

      if (!this.$data.user.id) {
        this.$data.messageText = 'No user selected';
        this.$data.dialogMessage = true;
        this.$data.sDisabled = false;
        return;
      }
      fetch(`${this.$data.host}${this.$data.paths.users}/${this.$data.user.id}/bandwidth/clear`, {
        method: 'GET',
        mode: 'cors', // no-cors, *cors, same-origin
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.$data.token}`
        },
      })
      .then(response => {
        if (response.ok) {
          console.log('OK');
          return response.json()
        } else {
          console.log('NOT OK');
          console.log(response);
          return response.json();
        }
      })
      .then(json => {
        console.log('DATA:', json);
        if (json && json.status === 'SUCCESS') {
          this.$data.messageText = 'Successfully updated!';
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
          this.$router.push(this.$route.query.redirect || '/')
        } else if (json && json.error) {
          this.$data.messageText = json.error;
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
        } else {
          this.$data.messageText = 'Authentication error';
          this.$data.dialogMessage = true;
          this.$data.sDisabled = false;
        }
      })
      .catch((e) => {
        // alert('Server error');
        console.log('Fetch error', e);
        this.$data.sDisabled = false;
      });
    },
    showHidePass: function() {
      if (this.$data.showPassword == 'password') {
        this.$data.showPassword = 'text';
      } else {
        this.$data.showPassword = 'password';
      }
    },
    enterClicked: function(event) {
      if (!event.target.value || event.target.value.length < 2) {
        this.$data.messageText = 'Enter at least 2 characters';
        this.$data.dialogMessage = true;
        return;
      }
      this.getResellerInfo(event.target.value);
    },

    changeUser: function() {
      console.log('USER CHANGE:');
      console.log(this.$data.selectedUser);
        const proxyUserId = this.$data.selectedUser;
        if (!proxyUserId) {
          return;
        }
        this.$data.dialogLoading = true;
        const path = `${this.$data.paths.users}/${proxyUserId}/info`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                let buf = {username: json.data.login, id: json.data.id};
                this.$data.reseller.usersList.push(buf);
                this.$data.selectedUser = buf.id;
                this.$data.user.id = json.data.id;
                this.$data.user.login = json.data.login;
                this.$data.user.email = json.data.email;
                this.$data.user.active = json.data.proxyUserStatus === 'ACTIVE' ? true : false;
                this.$data.user.created = json.data.created;
                this.$data.user.balance = json.data.trafficBalanceKb;
                this.$data.user.balanceString = json.data.trafficBalanceString;
                this.$data.user.description = json.data.description;
                this.$data.dialogLoading = false;
              } catch(e) {
                this.$data.dialogLoading = false;
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              this.$data.dialogLoading = false;
              this.$data.messageText = json.error;
              this.$data.dialogMessage = true;
            } else {
              throw new Error('Authentication error');
            }
          })
          .catch((e) => {
            this.$data.dialogLoading = false;
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    }
  },
  beforeMount() {
     this.getResellerMainInfo();
     this.getProxyUserInfo();
     this.getIPs();
  },
};
</script>
