<template>
  <div>
    <v-img
      src="@/assets/img/hero.svg"
      height="500"
      position="center top"
    >
      <div class="d-flex align-center h-100">
        <div class="mask bg-gradient-default opacity-9"></div>
        <v-container fluid class="z-index-1 px-6">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <h1 class="display-h-2 text-white font-weight-600 mb-2">
                New proxy user profile
              </h1>
              <p class="text-white mt-0 mb-12 font-weight-thin">
                Create a new proxy user profile and assign him a quota within which he can use the service. You cannot assign more traffic to a user than is currently available in your reseller account.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-img>
    <v-row class="px-6 mt-n16">
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="card-shadow mb-6 bg-gradient-info">
              <v-card-text class="card-stats-padding">
                <v-row no-gutters>
                  <v-col>
                    <div
                      class="font-weight-600 text-white text-uppercase text-h5"
                    >
                      Total bandwidth available
                    </div>
                    <p class="font-weight-600 text-h2 text-white mb-0">
                      {{ reseller.balanceString }}
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <v-avatar color="#fff">
                      <v-icon size="20" class="text-default">
                        ni-active-40
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>

                <p
                  class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
                >
                  <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                  <span class="text-white me-2">increased</span> since last month
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="card-shadow bg-gradient-danger mb-6">
              <v-card-text class="card-stats-padding">
                <v-row no-gutters>
                  <v-col>
                    <div
                      class="font-weight-600 text-white text-uppercase text-h5"
                    >
                      Total proxy users
                    </div>
                    <p class="font-weight-600 text-h2 text-white mb-0">
                      {{ reseller.usersTotal }}
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <v-avatar color="#fff">
                      <v-icon size="20" class="text-default">
                        ni-spaceship
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>

                <p
                  class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
                >
                  <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                  <span class="text-white me-2">increased</span> since last month
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <v-row>
              <v-col>
                <p class="font-weight-600 text-h3 text-typo mb-0">
                  Create Profile
                </p>
              </v-col>

              <v-col class="text-right">

              </v-col>
            </v-row>
          </div>
          <v-card-text class="card-padding">
            <v-form>
              <h6
                class="text-caption text-uppercase font-weight-600 text-muted mb-8"
              >
                User information
              </h6>
              <div class="ps-lg-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Username</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      v-model="user.login"
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="lucky.jesse"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Email address</label
                    >
                    <v-text-field
                      type="email"
                      hide-details
                      outlined
                      v-model="user.email"
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="lucky@example.com"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >First name</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      v-model="user.name"
                      class="input-style font-size-input text-light-input placeholder-light mb-5"
                      placeholder="Lucky"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Last name</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      v-model="user.lastname"
                      class="input-style font-size-input text-light-input placeholder-light mb-5"
                      placeholder="jesse"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Password</label
                    >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showPassword"
                    v-model="user.password"
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <v-icon @click="showHidePass" size=".875rem" color="#adb5bd">fas fa-eye</v-icon>
                    </template>
                  </v-text-field>

                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Confirm password</label
                    >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showPassword"
                    v-model="user.password2"
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <v-icon @click="showHidePass" size=".875rem" color="#adb5bd">fas fa-eye</v-icon>
                    </template>
                  </v-text-field>
                  </v-col>
                </v-row>

              </div>
            </v-form>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Proxy access configuration
            </h6>

            <div class="ps-lg-5">
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Available bandwidth [GB]</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    v-model="user.balanceGb" :min="0" :max="reseller.limitGb"
                    type="number"
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    placeholder="1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="pb-0">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Available Bandwidth</label
                  >
                    <v-slider
                      v-model="user.balanceGb" :min="0" :max="reseller.limitGb"
                      color="bg-primary"
                      track-color="#e9ecef"
                      class="progress-shadow mb-2"
                      thumb-label
                      hint="Im a hint"
                    ></v-slider>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Activate account</label
                  >

                    <v-switch
                      :ripple="false"
                      class="d-inline-flex mt-0 pt-0 switch me-2"
                      v-model="user.active"
                      hide-details
                      color="#292B2D"
                      inset
                    ></v-switch>

                </v-col>
              </v-row>

            </div>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Description
            </h6>

            <div class="ps-lg-5">
              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >About the user</label
                  >
                  <v-textarea
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light"
                    dense
                    flat
                    filled
                    solo
                    height="106"
                    name="input-7-4"
                    v-model="user.description"
                    placeholder="Amazon scraping, ~300k requests/day"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>

            <div class="border-bottom my-5"></div>

                <!-- <v-checkbox
                  :error-messages="checkboxErrors"
                  color="#5e72e4"
                  :ripple="false"
                  class="ma-0 checkbox-custom checkbox-thinner mb-5"
                  required
                  hide-details
                  v-model="user.terms"
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                >
                  <template v-slot:label>
                    <span class="text-body text-body-2 ls-0 checkbox-validation"
                      >Agree to terms and conditions</span
                    >
                  </template>
                </v-checkbox> -->

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  :disabled="sDisabled"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#ef4036"
                  >Submit</v-btn
                >

                <v-btn
                  @click="clear"
                  elevation="0"
                  :ripple="false"
                  :disabled="sDisabled"
                  height="43"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
                  color="#ef4036"
                  >Clear</v-btn
                >

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>
<script>
import CardProfile from "../Components/Cards/CardProfile.vue";
import ProgressTrack from "../Dashboard/Widgets/ProgressTrack.vue";

export default {
  components: { CardProfile, ProgressTrack },
  name: "profile",
  data() {
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        resellerInfo: `${this.$siteConfig.resellerApiBasePath}/reseller/info`,
        create: `${this.$siteConfig.resellerApiBasePath}/reseller/users/create`,
        users: `${this.$siteConfig.resellerApiBasePath}/reseller/users`,
        usersCount: `${this.$siteConfig.resellerApiBasePath}/reseller/users-count`,
      },
      sDisabled: false,
      showPassword: 'password',
      reseller: {balance: 10000, balanceString: '0 KB', limitGb: 0, usersTotal: 0},
      user: {
        login: '',
        name: '',
        lastname: '',
        email: '',
        active: false,
        balance: 0,
        balanceGb: 0,
        balanceString: '',
        description: '',
        password: '',
        password2: '',
        terms: false,
      },
    }
  },
  methods: {
    getResellerMainInfo: function() {
        fetch(`${this.$data.host}${this.$data.paths.usersCount}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);
            console.log(this.$data.reseller);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.reseller.balance = json.data.trafficBalanceKb;
                this.$data.reseller.limitGb = Math.floor(json.data.trafficBalanceKb / 1024 / 1024);
                this.$data.reseller.balanceString = json.data.trafficBalanceString;
                this.$data.reseller.usersTotal = json.data.users.all;
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }
            console.log(this.$data.reseller);

          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },

    getResellerInfo: function() {
        fetch(`${this.$data.host}${this.$data.paths.resellerInfo}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);
            console.log(this.$data.reseller);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.reseller.balance = json.data.trafficBalanceKb;
                this.$data.reseller.limitGb = Math.floor(json.data.trafficBalanceKb / 1024 / 1024);
                this.$data.reseller.balanceString = json.data.trafficBalanceString;
                this.$data.reseller.usersTotal = json.data.proxyUsers.all.length;
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }
            console.log(this.$data.reseller);

          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },
    submit: function() {
      this.$data.sDisabled = true;
      console.log('CLICKED!!!');
      let name = `${this.$data.user.name || ''} ${this.$data.user.lastname || ''}`.trim();
      let description = this.$data.user.description;
      let fullDescription = description;
      if (name && description) {
        fullDescription = `${name} | ${description}`;
      } else if (name) {
        fullDescription = name;
      }

      let createBody = {email: this.$data.user.email, login: this.$data.user.login, password: this.$data.user.password, description: fullDescription};

      if (createBody.email && createBody.password && createBody.login) {
        if (!/^[a-zA-Z0-9_-]*$/.test(createBody.password)) {
          alert('Password cannot contain special characters or whitespaces. Please use a password that does not contain special characters.')
          this.$data.sDisabled = false;
          return;
        }
        if (!/^[a-zA-Z0-9_-]*$/.test(createBody.login)) {
          alert('Login cannot contain special characters or whitespaces. Please use a login that does not contain special characters.')
          this.$data.sDisabled = false;
          return;
        }
        fetch(`${this.$data.host}${this.$data.paths.create}`, {
          method: 'PUT',
          mode: 'cors', // no-cors, *cors, same-origin
          // credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.$data.token}`
          },
          body: JSON.stringify(createBody) // body data type must match "Content-Type" header
        })
          .then(response => {
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              console.log(response);
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);
            if (json && json.userId) {
              // this.$router.push(this.$route.query.redirect || '/')
              this.$data.paths.add = `${this.$data.paths.users}/${json.userId}/bandwidth/add?megabytes=${Math.ceil(this.$data.user.balanceGb * 1024)}`;
              this.$data.paths.activate = `${this.$data.paths.users}/${json.userId}/activate`;

              return fetch(`${this.$data.host}${this.$data.paths.add}`, {
                mode: 'cors', // no-cors, *cors, same-origin
                // credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                  'Authorization': `Bearer ${this.$data.token}`
                },
              })
            } else if (json && json.error) {
              alert(json.error);
              this.$data.sDisabled = false;
            } else {
              alert('Authentication error');
              this.$data.sDisabled = false;
            }
          })
          .then(response => {
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              console.log(response);
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.status === 'SUCCESS') {
              if (this.$data.user.active) {
                return fetch(`${this.$data.host}${this.$data.paths.activate}`, {
                  mode: 'cors', // no-cors, *cors, same-origin
                  // credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                    'Authorization': `Bearer ${this.$data.token}`
                  },
                })
              } else {
                return {
                  ok: true,
                  json: () => {
                    console.log('CALLED MOCK');
                    return {status: 'SUCCESS'};
                  }
                }
              }
            } else if (json && json.error) {
              alert(json.error);
              this.$data.sDisabled = false;
            } else {
              alert('Authentication error');
              this.$data.sDisabled = false;
            }         
          })
          .then(response => {
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);
            if (json && json.status === 'SUCCESS') {
              alert('Successfully created!');
              const creds = {
                login: this.$data.user.login,
                email: this.$data.user.email,
                password: this.$data.user.password,
              };
              const encoded = btoa(JSON.stringify(creds));

              localStorage.setItem('createdUser', encoded);
              this.$router.push(this.$route.query.redirect || '/proxy-access')
            } else if (json && json.error) {
              alert(json.error);
              this.$data.sDisabled = false;
            } else {
              alert('Authentication error');
              this.$data.sDisabled = false;
            }
          })
          .catch((e) => {
            // alert('Server error');
            console.log('Fetch error', e);
            this.$data.sDisabled = false;
        });
      } else {
        alert('Email, login and password are required!')
        this.$data.sDisabled = false;
      }
    },
    clear: function() {
      this.$data.user.login = '';
      this.$data.user.name = '';
      this.$data.user.lastname = '';
      this.$data.user.email = '';
      this.$data.user.active = false;
      this.$data.user.balance = 0;
      this.$data.user.balanceGb = 0;
      this.$data.user.balanceString = '';
      this.$data.user.description = '';
      this.$data.user.password = '';
      this.$data.user.password2 = '';
      this.$data.user.terms = false;
    },
    showHidePass: function() {
      if (this.$data.showPassword == 'password') {
        this.$data.showPassword = 'text';
      } else {
        this.$data.showPassword = 'password';
      }
    },
    checkboxErrors: function() {},
  },
  beforeMount() {
     this.getResellerMainInfo();
  }
};
</script>
