<template>
  <v-card class="card-shadow mb-6" :class="bgColor">
    <v-card-text class="card-padding text-center card-border-bottom">
      <div
        class="display-h-2 font-weight-600"
        :class="{ 'text-white': hasBg, 'text-typo': plainBg }"
      >
        {{ user.balanceString }}
      </div>
      <span
        class="font-size-root"
        :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
        >available</span
      >

      <p
        class="font-size-root font-weight-600 mb-0 mt-4"
        :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
        >Client IPs:</p
      >
      <p
        class="font-size-root mb-0"
        :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
        >{{ user.ips.join(', ') }}</p
      >

      <v-list class="py-6 mx-auto" color="transparent" width="325">
        <v-list-item
          v-for="item in features"
          :key="item.title"
          class="px-0 py-0"
        >
          <v-list-item-avatar
            :size="24"
            color="#fff"
            class="my-0 me-2"
            :class="{ 'bg-gradient-primary': plainBg }"
          >
            <v-icon
              size="9"
              :class="{ 'text-white': plainBg, 'text-muted': hasBg }"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title
              v-text="item.title"
              class="text-body-2 ls-0"
              :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: "card-pricing",
  props: {
    bgColor: String,
    selectedUser: String,
  },
  data() {
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        users: `${this.$siteConfig.resellerApiBasePath}/reseller/users`,
      },
      user: {
        id: '',
        ips: ['-'],
        login: '',
        email: '',
        active: false,
        created: '',
        balance: 0,
        balanceString: '0 KB',
        description: '',
      },
      hasBg: this.bgColor != undefined ? true : false,
      plainBg: this.bgColor === undefined ? true : false,
      features: [
        {
          icon: "fas fa-terminal",
          title: "Username: ",
        },
        {
          icon: "fas fa-pen-fancy",
          title: "Email: ",
        },
        {
          icon: "fas fa-hdd",
          title: "Registration date: ",
        },
      ],
    };
  },
  watch: {
    selectedUser: function (val, oldVal) {
        console.log('CHANGED', JSON.stringify(val));
        const proxyUserId = val;
        if (!proxyUserId) {
          return;
        }
        const path = `${this.$data.paths.users}/${proxyUserId}/info`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.user.id = json.data.id;
                this.$data.user.ips = '-';
                this.$data.user.login = json.data.login;
                this.$data.user.email = json.data.email;
                this.$data.user.active = json.data.status === 'ACTIVE' ? true : false;
                this.$data.user.created = new Date(json.data.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' });
                this.$data.user.balance = json.data.trafficBalanceKb;
                this.$data.user.balanceString = json.data.trafficBalanceString;
                this.$data.user.description = json.data.description;

                this.$data.features[0].title = `Username: ${json.data.login}`;
                this.$data.features[1].title = `Email: ${json.data.email}`;
                this.$data.features[2].title = `Registration date: ${new Date(json.data.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' })}`;
                this.getIPs(proxyUserId);
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }

// handle data
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },
  },

  methods: {
    getIPs: function(uid) {
      console.log(this.$route.query.user);
        const proxyUserId = uid || this.$route.query.user;
        if (!proxyUserId) {
          return;
        }
        const path = `${this.$data.paths.users}/${proxyUserId}/client-ips`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.user.ips = json.data;
              } catch(e) {
                this.$data.user.ips = '-';
                console.log('Parsing error!!!!!!!!!!!', e);
              }

            } else if (json && json.error) {
              // this.$data.messageText = json.error;
              // this.$data.dialogMessage = true;
              this.$data.user.ips = '-';
              console.log('Can not fetch client IPs list', json.error);
            } else {
              // throw new Error('Authentication error');
              console.log('Can not fetch client IPs list');
              this.$data.user.ips = '-';
            }
          })
          .catch((e) => {
            console.log('Fetch error', e);
            this.$data.user.ips = '-';
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
        });
    },

    getProxyUserInfo: function() {
      console.log('FROM COMP', JSON.stringify(this.selectedUser))
      console.log(this.$route.query.user);
        const proxyUserId = this.$route.query.user;
        if (!proxyUserId) {
          return;
        }
        const path = `${this.$data.paths.users}/${proxyUserId}/info`;

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.user.id = json.data.id;
                this.$data.user.login = json.data.login;
                this.$data.user.email = json.data.email;
                this.$data.user.active = json.data.status === 'ACTIVE' ? true : false;
                this.$data.user.created = new Date(json.data.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' });
                this.$data.user.balance = json.data.trafficBalanceKb;
                this.$data.user.balanceString = json.data.trafficBalanceString;
                this.$data.user.description = json.data.description;

                this.$data.features[0].title = `Username: ${json.data.login}`;
                this.$data.features[1].title = `Email: ${json.data.email}`;
                this.$data.features[2].title = `Registration date: ${new Date(json.data.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' })}`;
              } catch(e) {
                console.log('Parsing error!!!!!!!!!!!', e);
              }

// handle data
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            // console.log('Fetch error', e)
        });
    }
  },
  beforeMount(){
     this.getProxyUserInfo();
     this.getIPs();
  },
};
</script>
